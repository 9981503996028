import { useLoaderData } from "react-router-dom";
import { generateGetLoader } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";

export async function loader({ params }) {
  const data = await generateGetLoader(
    [`${process.env.REACT_APP_BASE_URL}/api/item/get/${params.id}`],
    ["item"],
  )();
  return data;
}

export default function Accessories() {
  const { item } = useLoaderData();

  return (
    <>
      <BreadCrums
        segments={[
          { label: "Items", link: "/user/items" },
          {
            label: item.Item_Name,
            link: encodeURI(
              `/user/items/${item.id}?name=${item.Item_Name}&im=${item.Item_Image}&iml=${item.Item_Image_Large}`,
            ),
          },
          { label: "Accessories", link: "." },
        ]}
      />
    </>
  );
}
