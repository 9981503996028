import * as React from "react";
import styles from "./image-picker.module.css";

export default function ImagePicker({ name, id, image, label }) {
  const [file, setFile] = React.useState("");
  const inputRef = React.useRef();

  function handleImageChange(e) {
    if (
      e.target.files &&
      e.target.files[0] &&
      e.target.files[0].size > 2097152
    ) {
      alert("Please upload an image smaller than 2MB");
      inputRef.current.value = "";
      return;
    }
    if (e.target.files && e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }

  return (
    <div>
      <label className={styles.text} htmlFor={id}>
        {label || "Update Photo"}
      </label>
      <input
        type="file"
        hidden
        name={name}
        id={id}
        onChange={handleImageChange}
        ref={inputRef}
        accept="image/*"
      />
      {file || image ? (
        <img
          src={file || image}
          alt="preview"
          className={styles.previewImage}
          id={`${id}#element`}
        />
      ) : (
        <div className={styles.previewImage} />
      )}
    </div>
  );
}
