import { Link, useLoaderData } from "react-router-dom";
import Modal from "../../../components/modal";
import styles from "./confirm.module.css";

export function loader({ request, params }) {
  const url = new URL(request.url);
  const itemId = params.id;
  const action = url.searchParams.get("action");
  return { itemId, action };
}

export default function AccessoriesConfirm() {
  const { action, itemId } = useLoaderData();
  return (
    <Modal open>
      <div className={styles.modalContainer}>
        <div className={styles.modalPrompt}>
          Item {action}d successfully. Would you like to {action} accessories?
        </div>
        <div className={styles.modalActionContainer}>
          <Link
            to="/user/items"
            className={`${styles.button} ${styles.buttonOutlined}`}
          >
            No
          </Link>
          <Link
            to={`/user/items/${itemId}/accessories`}
            className={`${styles.button} ${styles.buttonFilled}`}
          >
            Yes
          </Link>
        </div>
      </div>
    </Modal>
  );
}
