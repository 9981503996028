import ShapeDetails from "../shape-details";
import { generateGetLoader } from "../../../lib/helpers";
import { useLoaderData, useNavigation, redirect } from "react-router-dom";
import * as React from "react";
import { createPostRequest, uploadImage } from "../../../lib/network";

export async function loader({ params }) {
  const id = params.id;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/flashingShape/getOne/${id}`,
    "shape",
  )();
  return response;
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const name = formData.get("Flashing_Shape_Name");
  const category = formData.get("Flashing_Shape_Category");
  const buttonElement = document.getElementById("shape-button");
  const diagram = buttonElement.getAttribute("data-diagram");
  const imgElement = document.getElementById("shape-image");
  const imgSrc = imgElement.getAttribute("src");
  let img;
  if (imgSrc.charAt(0).toLowerCase() === "h") {
    const segment = imgSrc.split("/");
    img = { imageName: segment[segment.length - 1] };
  } else {
    img = await uploadImage(imgSrc, false);
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/flashingShape/update`;
  await createPostRequest(url, {
    Flashing_Shape_Name: name,
    Flashing_Shape_Data: diagram,
    Flashing_Shape_Image: img.imageName,
    UpdateFlashingShapeID: params.id,
    Flashing_Shape_Category: category,
  });
  return redirect("..");
}

export default function UpdateShape() {
  const { shape } = useLoaderData();
  const { state } = useNavigation();
  const [formChanged, setFormChanged] = React.useState(false);

  return (
    <ShapeDetails
      onFormChange={() => setFormChanged(true)}
      heading={shape.Flashing_Shape_Name}
      image={`${process.env.REACT_APP_IMAGE_PREFIX}/${shape.Flashing_Shape_Image}`}
      name={shape.Flashing_Shape_Name}
      category={shape.Flashing_Shape_Category}
      diagram={shape.Flashing_Shape_Data}
      showSubmit={formChanged}
      submitting={state === "submitting"}
    />
  );
}
