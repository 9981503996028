import {
  isRouteErrorResponse,
  useRouteError,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "./layout";
import useToken from "../hooks/useToken";
import React from "react";
import { captureException } from "@sentry/react";

export default function ErrorPage() {
  const error = useRouteError();
  const location = useLocation();
  const token = useToken();

  React.useEffect(() => {
    if (error && token) {
      captureException(error, { level: "error" });
    }
  }, [error, token]);

  if (!token) {
    return <Navigate to={`/?next=${encodeURI(location.pathname)}`} replace />;
  }

  return (
    <Layout>
      <div>
        {isRouteErrorResponse(error) ? (
          <div>
            {error.data.message || "Something went wrong, please try again"}
          </div>
        ) : (
          <div>Something went wrong, please try again</div>
        )}
      </div>
    </Layout>
  );
}
