import { useLoaderData, useNavigation, Form, redirect } from "react-router-dom";
import BreadCrums from "../../../components/breadcrums";
import { generateGetLoader } from "../../../lib/helpers";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { formatFormData } from "../../helpers";
import styles from "./detail.module.css";
import ItemDetail from "../item-detail";
import Variant from "../variant";
import Branches from "../branches";
import PrimaryButton from "../../../components/primary-button";
import { createPostRequest, uploadImage } from "../../../lib/network";

export async function loader({ params }) {
  const data = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/industry/getMany`,
      `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colour/getAll`,
      `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany`,
      `${process.env.REACT_APP_BASE_URL}/api/item/getBranchItemsForItem/${params.id}`,
    ],
    ["industries", "suppliers", "colours", "categories", "variants"],
  )();
  if (data.variants.length > 0) {
    const first = data.variants[0];
    const colourToCategory = data.colours.reduce((acc, curr) => {
      acc[curr._id] = curr.Colour_Category._id;
      return acc;
    }, {});
    const variantSizeIds = {};
    const form = {
      item: {
        Item_Name: first.Branch_Item.Item_Name,
        Item_Supplier: first.Branch_Item.Item_Supplier,
        Item_Industry: first.Branch_Item.Item_Industry,
        Item_Category: first.Branch_Item.Item_Category,
        Item_Description: first.Branch_Item.Item_Description,
        Default_Branch: first.Branch_ID,
        Item_Image: first.Branch_Item.Item_Image,
        Item_Image_Large: first.Branch_Item.Item_Image_Large,
      },
      branches: {},
      variants: {},
    };
    const baseVariant = first.Branch_Item_Variants[0];
    data.variants.forEach((branchItem) => {
      form.branches[branchItem.Branch_ID] = {};
      form.branches[branchItem.Branch_ID]["Branch_Delivery_Cost"] =
        branchItem.Branch_Item_Variants[0].Variant_Delivery_Charges;
      form.branches[branchItem.Branch_ID]["Branch_EDT"] =
        branchItem.Branch_Item_Variants[0].Variant_Estimated_Delivery_Time;
      const branchBaseVariant = branchItem.Branch_Item_Variants.find(
        (item) =>
          item.Variant_Size_ID === baseVariant.Variant_Size_ID &&
          item.Variant_Colour === baseVariant.Variant_Colour,
      );
      form.branches[branchItem.Branch_ID]["Branch_Cost_Price"] =
        branchBaseVariant.Variant_Cost_Price;
      form.branches[branchItem.Branch_ID]["Branch_Selling_Price"] =
        branchBaseVariant.Variant_Selling_Price;
      if (branchItem.Branch_ID === first.Branch_ID) {
        branchItem.Branch_Item_Variants.forEach((variant) => {
          const sizeId = variant.Variant_Size_ID;
          if (!variantSizeIds[sizeId]) {
            variantSizeIds[sizeId] = true;
          }
          const id = sizeId;
          if (!form.variants[id]) {
            form.variants[id] = {};
          }
          form.variants[id]["Variant_Size"] = variant.Variant_Size;
          form.variants[id]["Variant_Size_Type"] = variant.Variant_Size_Type;
          if (variant.Variant_Size_Type === "Non Standard") {
            form.variants[id][
              "Variant_Size_Type_Option"
            ] = `${variant.Variant_Size_Type_Option}#${id}`;
            form.variants[id][
              "Variant_Size_Type_Units"
            ] = `${variant.Variant_Size_Type_Units}#${id}`;
            form.variants[id]["Variant_Size_Min"] = variant.Variant_Size_Min;
            form.variants[id]["Variant_Size_Max"] = variant.Variant_Size_Max;
          }
          if (variant.Variant_Warehouse_Location) {
            form.variants[id]["Variant_Stock"] = "Stocked";
            form.variants[id]["Variant_Warehouse_Location"] =
              variant.Variant_Warehouse_Location;
          } else {
            form.variants[id]["Variant_Stock"] = "Not Stocked";
          }

          const cat = variant.Variant_Colour
            ? colourToCategory[variant.Variant_Colour]
            : "Unpainted";
          const catId = `${cat}#${id}`;
          if (
            !form.variants[id]["Variant_Colour"] ||
            !form.variants[id]["Variant_Colour"][cat]
          ) {
            if (!form.variants[id]["Variant_Colour"]) {
              form.variants[id]["Variant_Colour_Type"] = [catId];
              form.variants[id]["Variant_Colour"] = {
                [cat]: {
                  costPrice: variant.Variant_Cost_Price,
                  price: variant.Variant_Selling_Price,
                  colours: variant.Variant_Colour
                    ? [`${variant.Variant_Colour}#${id}`]
                    : undefined,
                },
              };
            } else if (!form.variants[id]["Variant_Colour"][cat]) {
              form.variants[id]["Variant_Colour_Type"].push(catId);
              form.variants[id]["Variant_Colour"][cat] = {
                costPrice: variant.Variant_Cost_Price,
                price: variant.Variant_Selling_Price,
                colours: variant.Variant_Colour
                  ? [`${variant.Variant_Colour}#${id}`]
                  : undefined,
              };
            }
          } else {
            form.variants[id]["Variant_Colour"][cat].colours.push(
              `${variant.Variant_Colour}#${id}`,
            );
          }
        });
      }
    });
    const baseCP = form.branches[form.item.Default_Branch].Branch_Cost_Price;
    const baseSP = form.branches[form.item.Default_Branch].Branch_Selling_Price;
    Object.entries(form.branches).forEach(([branchId, data]) => {
      if (branchId === form.item.Default_Branch) {
        form.branches[branchId].Branch_Cost_Price = 0;
        form.branches[branchId].Branch_Selling_Price = 0;
      } else {
        form.branches[branchId].Branch_Cost_Price = parseFloat(
          ((data.Branch_Cost_Price / baseCP) * 100 - 100).toFixed(2),
        );
        form.branches[branchId].Branch_Selling_Price = parseFloat(
          ((data.Branch_Selling_Price / baseSP) * 100 - 100).toFixed(2),
        );
      }
    });
    data.form = { data: form, variants: Object.keys(variantSizeIds) };
  }
  return data;
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const formattedData = formatFormData(formData);
  delete formattedData.item.Default_Branch;
  const requestBody = {
    Items: [
      {
        ...formattedData.item,
        Variants: [],
        branchMapping: [],
      },
    ],
  };
  Object.entries(formattedData.variants).forEach(([variantId, data]) => {
    const variantsForSize = [];
    const branchItemsForSize = [];
    delete data.Variant_Colour_Type;
    Object.values(data.Variant_Colour).forEach((record) => {
      if (record.colours) {
        record.colours.forEach((colour) => {
          const obj = {
            ...data,
            Variant_Colour: colour.split("#")[0],
            Variant_Selling_Price: record.price,
            Variant_Cost_Price: record.costPrice,
            Variant_Client_ID: uuidv4(),
            Variant_Size_ID: variantId,
          };
          if (obj.Variant_Size_Min) {
            obj.Variant_Size_Min = parseFloat(obj.Variant_Size_Min);
          }
          if (obj.Variant_Size_Max) {
            obj.Variant_Size_Max = parseFloat(obj.Variant_Size_Max);
          }
          if (obj.Variant_Size_Type_Option) {
            obj.Variant_Size_Type_Option =
              obj.Variant_Size_Type_Option.split("#")[0];
          }
          if (obj.Variant_Size_Type_Units) {
            obj.Variant_Size_Type_Units =
              obj.Variant_Size_Type_Units.split("#")[0];
          }
          variantsForSize.push(obj);
        });
      } else {
        const obj = {
          ...data,
          Variant_Colour: null,
          Variant_Client_ID: uuidv4(),
          Variant_Selling_Price: record.price,
          Variant_Cost_Price: record.costPrice,
          Variant_Size_ID: variantId,
        };
        if (obj.Variant_Size_Min) {
          obj.Variant_Size_Min = parseFloat(obj.Variant_Size_Min);
        }
        if (obj.Variant_Size_Max) {
          obj.Variant_Size_Max = parseFloat(obj.Variant_Size_Max);
        }
        if (obj.Variant_Size_Type_Option) {
          obj.Variant_Size_Type_Option =
            obj.Variant_Size_Type_Option.split("#")[0];
        }
        if (obj.Variant_Size_Type_Units) {
          obj.Variant_Size_Type_Units =
            obj.Variant_Size_Type_Units.split("#")[0];
        }
        variantsForSize.push(obj);
      }
    });
    variantsForSize.forEach((variant) => {
      Object.entries(formattedData.branches).forEach(
        ([branchId, branchData]) => {
          branchItemsForSize.push({
            Branch_ID: branchId,
            Variant_Client_ID: variant.Variant_Client_ID,
            Variant_Cost_Price: parseFloat(
              (
                (1 + parseFloat(branchData.Branch_Cost_Price || "0") * 0.01) *
                parseFloat(variant.Variant_Cost_Price)
              ).toFixed(2),
            ),
            Variant_Selling_Price: parseFloat(
              (
                (1 +
                  parseFloat(branchData.Branch_Selling_Price || "0") * 0.01) *
                parseFloat(variant.Variant_Selling_Price)
              ).toFixed(2),
            ),
            Variant_Delivery_Charges: parseFloat(
              branchData.Branch_Delivery_Cost,
            ),
            Variant_Estimated_Delivery_Time: parseFloat(branchData.Branch_EDT),
          });
        },
      );
      delete variant.Variant_Cost_Price;
      delete variant.Variant_Selling_Price;
    });
    requestBody.Items[0].Variants.push(...variantsForSize);
    requestBody.Items[0].branchMapping.push(...branchItemsForSize);
  });

  const image = formData.get("Item_Image");
  if (image instanceof Blob && image.size > 0) {
    const response = await uploadImage(image);
    requestBody.Items[0].Item_Image = response.imageName;
  } else {
    const element = document.getElementById("item-image#element");
    const segments = element?.getAttribute("src")?.split("/");
    if (segments) {
      requestBody.Items[0].Item_Image = segments[segments.length - 1];
    }
  }

  const imageLarge = formData.get("Item_Image_Large");
  if (imageLarge instanceof Blob && imageLarge.size > 0) {
    const response = await uploadImage(imageLarge);
    requestBody.Items[0].Item_Image_Large = response.imageName;
  } else {
    const element = document.getElementById("item-image-large#element");
    const segments = element?.getAttribute("src")?.split("/");
    if (segments) {
      requestBody.Items[0].Item_Image_Large = segments[segments.length - 1];
    }
  }
  const postUrl = `${process.env.REACT_APP_BASE_URL}/api/item/update`;
  await createPostRequest(postUrl, {
    UpdateItemID: params.id,
    payload: requestBody,
  });
  return redirect(`/user/items/${params.id}/accessories/confirm?action=update`);
}

export default function Detail() {
  const { form, suppliers, industries, colours, categories } = useLoaderData();
  const [variantIds, setVariantIds] = React.useState(form.variants);
  const [step, setStep] = React.useState(1);
  const [branches, setBranches] = React.useState(
    form.data.item.Item_Supplier
      ? () => {
          const newSup = suppliers.find(
            (item) => item._id === form.data.item.Item_Supplier,
          );
          return newSup.Branches;
        }
      : [],
  );
  const { state } = useNavigation();
  const formRef = React.useRef();
  const submitButtonRef = React.useRef();

  function addVariant(e) {
    e.preventDefault();
    setVariantIds((curr) => [...curr, uuidv4()]);
  }

  function handleSubmit() {
    submitButtonRef.current.setAttribute("disabled", "true");
    const formData = new FormData(formRef.current);
    const formattedData = formatFormData(formData);
    let isValid = true;

    const industry = formData.getAll("Item_Industry");
    const category = formData.getAll("Item_Category");

    if (!industry || industry.length === 0) {
      document
        .querySelector(`[name="Item_Industry"]`)
        .setCustomValidity("Please select at least one industry");
      isValid = false;
    } else {
      document.querySelector(`[name="Item_Industry"]`).setCustomValidity("");
    }

    if (!category || category.length === 0) {
      document
        .querySelector(`[name="Item_Category"]`)
        ?.setCustomValidity("Please select at least one category");
      isValid = false;
    } else {
      document.querySelector(`[name="Item_Category"]`)?.setCustomValidity("");
    }

    if (Object.keys(formattedData.variants).length === 0) {
      document
        .getElementById(`variant-size-${variantIds[0]}`)
        .setCustomValidity("Please fill all sizes information");
      isValid = false;
    } else {
      document
        .getElementById(`variant-size-${variantIds[0]}`)
        .setCustomValidity("");
    }

    Object.entries(formattedData.variants).forEach(([variantId, data]) => {
      if (!data.Variant_Colour_Type || data.Variant_Colour_Type.length === 0) {
        document
          .querySelector(`[name="Variant_Colour_Type-${variantId}"]`)
          .setCustomValidity(
            'Please select at least one colour range. Select "Unpainted" if painted products don\'t exist',
          );
        isValid = false;
      } else {
        document
          .querySelector(`[name="Variant_Colour_Type-${variantId}"]`)
          .setCustomValidity("");
      }
      if (data.Variant_Colour) {
        Object.entries(data.Variant_Colour).forEach(([category, catData]) => {
          if (
            (!catData.colours || catData.colours.length === 0) &&
            category !== "Unpainted"
          ) {
            document
              .querySelector(`[name="Variant_Colour_${category}-${variantId}"]`)
              .setCustomValidity("Please select at least one colour");
            isValid = false;
          } else if (category !== "Unpainted") {
            document
              .querySelector(`[name="Variant_Colour_${category}-${variantId}"]`)
              .setCustomValidity("");
          }
        });
      }
    });
    submitButtonRef.current.removeAttribute("disabled");
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }
    formRef.current.requestSubmit();
  }

  function handleDelete(id) {
    setVariantIds((curr) => {
      if (curr.length === 1) {
        return curr;
      }
      return curr.filter((varId) => varId !== id);
    });
  }

  function updateBranches(newSupplier) {
    const newSup = suppliers.find((item) => item._id === newSupplier);
    setBranches(newSup.Branches);
    setStep(1);
  }

  function moveToVariants() {
    const formData = new FormData(formRef.current);
    const formattedData = formatFormData(formData);
    const activeBranches = Object.entries(formattedData.branches);
    if (activeBranches.length === 0) {
      alert("Item needs to be available in at least one branch");
      return;
    }
    let isValid = true;
    activeBranches.forEach(([branchId, data]) => {
      if (branchId !== formattedData.item.Default_Branch) {
        if (!data.Branch_Cost_Price) {
          document
            .getElementById(`branch-${branchId}-price`)
            .setCustomValidity("Cost price is required for non default branch");
          isValid = false;
        } else {
          document
            .getElementById(`branch-${branchId}-price`)
            .setCustomValidity("");
        }
        if (!data.Branch_Selling_Price) {
          document
            .getElementById(`branch-${branchId}-selling-price`)
            .setCustomValidity(
              "Selling price is required for non default branch",
            );
          isValid = false;
        } else {
          document
            .getElementById(`branch-${branchId}-selling-price`)
            .setCustomValidity("");
        }
      }

      if (!data.Branch_Delivery_Cost) {
        document
          .getElementById(`branch-${branchId}-delivery`)
          .setCustomValidity("Delivery cost is required");
        isValid = false;
      } else {
        document
          .getElementById(`branch-${branchId}-delivery`)
          .setCustomValidity("");
      }

      if (!data.Branch_EDT) {
        document
          .getElementById(`branch-${branchId}-edt`)
          .setCustomValidity("ETD is required");
        isValid = false;
      } else {
        document.getElementById(`branch-${branchId}-edt`).setCustomValidity("");
      }
    });

    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }

    setStep(2);
  }

  React.useEffect(() => {
    document.title = form?.data?.item?.Item_Name;
  }, [form?.data?.item?.Item_Name]);

  return (
    <>
      <BreadCrums
        segments={[
          { label: "Items", link: "../items" },
          { label: form?.data?.item?.Item_Name, link: "." },
        ]}
      />
      <Form
        className={styles.container}
        encType="multipart/form-data"
        method="post"
        ref={formRef}
      >
        <ItemDetail
          industries={industries}
          suppliers={suppliers}
          prevData={form.data.item}
          onSupplierChange={updateBranches}
        />
        <div className={styles.tabsContainer}>
          <div
            className={`${styles.tab} ${step === 1 ? styles.activeTab : ""}`}
            onClick={() => setStep(1)}
          >
            Branches
          </div>
          <div
            className={`${styles.tab} ${step === 2 ? styles.activeTab : ""} ${
              styles.noClick
            }`}
          >
            Sizes
          </div>
        </div>
        <section className={step === 1 ? styles.visible : styles.hidden}>
          <Branches
            branches={branches}
            onNext={moveToVariants}
            prevData={form.data.branches}
            prevDefaultBranch={form.data.item.Default_Branch}
            update
          />
        </section>
        <section className={step === 2 ? styles.visible : styles.hidden}>
          {variantIds.map((value, index) => (
            <Variant
              count={index + 1}
              key={value}
              id={value}
              prevData={
                form.data.variants ? form.data.variants[value] : undefined
              }
              onDelete={() => handleDelete(value)}
              colours={colours}
              categories={categories.colourCategories}
            />
          ))}
          <div className={styles.actionContainer}>
            <div>
              <PrimaryButton
                label="Add Variant"
                type="button"
                variant="outlined"
                onClick={addVariant}
              />
            </div>
            <PrimaryButton
              label="Update Item"
              type="button"
              onClick={handleSubmit}
              loading={state === "submitting"}
              ref={submitButtonRef}
            />
          </div>
        </section>
      </Form>
    </>
  );
}
