import { useNavigation, redirect } from "react-router-dom";
import ShapeDetails from "../shape-details";
import { createPostRequest, uploadImage } from "../../../lib/network";

export async function action({ request }) {
  const formData = await request.formData();
  const name = formData.get("Flashing_Shape_Name");
  const category = formData.get("Flashing_Shape_Category");
  const buttonElement = document.getElementById("shape-button");
  const diagram = buttonElement.getAttribute("data-diagram");
  const imgElement = document.getElementById("shape-image");
  const dataUrl = imgElement.getAttribute("src");
  const img = await uploadImage(dataUrl, false);
  const url = `${process.env.REACT_APP_BASE_URL}/api/flashingShape/create`;
  await createPostRequest(url, {
    Flashing_Shape_Name: name,
    Flashing_Shape_Data: diagram,
    Flashing_Shape_Image: img.imageName,
    Flashing_Shape_Category: category,
  });
  return redirect("..");
}

export default function CreateShape() {
  const { state } = useNavigation();

  return <ShapeDetails submitting={state === "submitting"} />;
}
