import * as React from "react";
import { useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import Palette from "../components/palette";
import styles from "./items.module.css";
// import Radio from "../components/radio";
import { generateGetLoader } from "../lib/helpers";
import { createPostRequest } from "../lib/network";
import SortDropdown from "./sort-dropdown";
import Searchbar from "../components/searchbar";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const sort = url.searchParams.get("sort") || "rank-dsc";
  const search = url.searchParams.get("q");
  let requestUrl = `${process.env.REACT_APP_BASE_URL}/api/item/getAll?page=${page}&sort=${sort}&limit=25`;
  if (search) {
    requestUrl = `${requestUrl}&q=${search}`;
  }
  const response = await generateGetLoader(requestUrl, "items")();
  return { ...response, sort, search };
}

export async function action({ request }) {
  const formData = await request.formData();
  const body = Object.fromEntries(formData);
  await createPostRequest(
    `${process.env.REACT_APP_BASE_URL}/api/item/update/entryStatus`,
    body,
  );
  return null;
}

function getItemsFromResponse(items, fetcher) {
  return items?.items.map((item) => {
    const colours = {};
    const sizes = new Set();
    // let priceMin = Math.min();
    // let priceMax = Math.max();
    // let cPriceMin = Math.min();
    // let cPriceMax = Math.max();
    // let stocked = false;

    item.Variants.forEach((variant) => {
      sizes.add(variant.Variant_Size);
      // priceMin = Math.min(priceMin, variant.Variant_Selling_Price);
      // priceMax = Math.max(priceMax, variant.Variant_Selling_Price);
      // cPriceMin = Math.min(cPriceMin, variant.Variant_Cost_Price);
      // cPriceMax = Math.max(cPriceMax, variant.Variant_Cost_Price);
      if (variant.Variant_Colour) {
        colours[variant.Variant_Colour.Colour_Code] = true;
      }
      // if (variant.InventoryID?.isWarehouseItem) {
      //   stocked = true;
      // }
    });

    const coloursList = Object.keys(colours);
    const renderedColurs =
      coloursList.length > 0 ? (
        <div className={styles.coloursContainer}>
          {coloursList.slice(0, 3).map((color) => (
            <Palette
              code={color}
              height={60}
              width={60}
              borderRadius={8}
              key={color}
            />
          ))}
          {coloursList.length > 3 && (
            <div className={styles.count}>+{coloursList.length - 3}</div>
          )}
        </div>
      ) : (
        <div className={styles.tableText}>Not Painted</div>
      );

    const renderedSizes = (
      <div className={styles.sizesContainer}>
        {Array.from(sizes).map((size) => (
          <div key={size}>{size}</div>
        ))}
      </div>
    );

    const image = item.Item_Image ? (
      <img
        src={`${process.env.REACT_APP_IMAGE_PREFIX}/${item.Item_Image}`}
        alt="item"
        className={styles.previewImage}
      />
    ) : (
      <div className={styles.previewImage} />
    );

    return [
      {
        value: item._id,
      },
      { value: image },
      { value: <span className={styles.tableText}>{item.Item_Name}</span> },
      { value: renderedColurs },
      { value: renderedSizes },
      {
        value: (
          <span className={styles.tableText}>
            {item.Item_Supplier?.Supplier_Name}
          </span>
        ),
      },
      {
        value: (
          <input
            type="checkbox"
            checked={item.Item_Entry_Status === "complete"}
            onClick={(e) => e.stopPropagation()}
            className={styles.completeCheckbox}
            onChange={(e) => {
              let status;
              const checked = e.target.checked;
              if (checked) {
                status = "complete";
              } else {
                status = "incomplete";
              }
              fetcher.submit(
                { UpdateItemID: item._id, Item_Entry_Status: status },
                { method: "POST" },
              );
            }}
            disabled={
              fetcher.state === "submitting" || fetcher.state === "loading"
            }
          />
          // <Radio
          //   defaultChecked={stocked}
          //   containerStyles={{ display: "block" }}
          // />
        ),
        align: "right",
      },
    ];
  });
}

export default function Items() {
  const { items, sort, search } = useLoaderData();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const currentPage = items.currentPage;
  const totalPages = items.totalPages;
  const [itemRows, setItemRows] = React.useState(
    getItemsFromResponse(items, fetcher),
  );

  function handleRowClick(id) {
    navigate(id);
  }

  function onDrop(rowIndex, afterIndex) {
    if (rowIndex === afterIndex) {
      return;
    } else if (rowIndex < afterIndex) {
      setItemRows((curr) => {
        const prevId = curr[afterIndex][0].value.split("?")[0];
        const itemId = curr[rowIndex][0].value.split("?")[0];
        const nextId =
          afterIndex + 1 < curr.length
            ? curr[afterIndex + 1][0].value.split("?")[0]
            : undefined;
        createPostRequest(`${process.env.REACT_APP_BASE_URL}/api/item/rank`, {
          prevId,
          itemId,
          nextId,
        });
        return [
          ...curr.slice(0, rowIndex),
          ...curr.slice(rowIndex + 1, afterIndex + 1),
          curr[rowIndex],
          ...curr.slice(afterIndex + 1),
        ];
      });
    } else {
      setItemRows((curr) => {
        const prevId =
          afterIndex >= 1
            ? curr[afterIndex - 1][0].value.split("?")[0]
            : undefined;
        const itemId = curr[rowIndex][0].value.split("?")[0];
        const nextId = curr[afterIndex][0].value.split("?")[0];
        createPostRequest(`${process.env.REACT_APP_BASE_URL}/api/item/rank`, {
          prevId,
          itemId,
          nextId,
        });
        return [
          ...curr.slice(0, afterIndex),
          curr[rowIndex],
          ...curr.slice(afterIndex, rowIndex),
          ...curr.slice(rowIndex + 1),
        ];
      });
    }
  }

  const sortOptions = [
    { label: "Name Ascending", value: "name-asc" },
    { label: "Name Descending", value: "name-dsc" },
    { label: "Date Ascending", value: "dateModified-asc" },
    { label: "Date Descending", value: "dateModified-dsc" },
    { label: "Rank", value: "rank-dsc" },
  ];

  React.useEffect(() => {
    setItemRows(getItemsFromResponse(items, fetcher));
  }, [items, fetcher]);

  React.useEffect(() => {
    document.title = "Admin Portal";
  }, []);

  function sortOrderChanged(newOrder) {
    let url = `/user/items?page=1&sort=${newOrder}`;
    if (search) {
      url = `${url}&q=${search}`;
    }
    navigate(url);
  }

  const sortOption = sort
    ? sortOptions.find((item) => item.value === sort)
    : { label: "Rank", value: "rank-dsc" };

  let nextPageLink = `?page=${currentPage + 1}&sort=${sort}`;
  let prevPageLink = `?page=${currentPage - 1}&sort=${sort}`;

  if (search) {
    nextPageLink = `${nextPageLink}&q=${search}`;
    prevPageLink = `${prevPageLink}&q=${search}`;
  }

  return (
    <>
      <PageHeader
        text="Items"
        extraSection={
          <div className={styles.sort}>
            <Searchbar
              onSubmit={(search) =>
                navigate(`/user/items?page=1&sort=${sort}&q=${search}`)
              }
            />
            <SortDropdown
              options={sortOptions}
              label="Sort By:"
              onChange={sortOrderChanged}
              value={sortOption}
              name="Sort_Order"
              id="sort"
            />
          </div>
        }
      />

      <Table
        headers={[
          { name: "Item", width: "21%" },
          { name: "Item Name", width: "18%" },
          { name: "Colour", width: "18%" },
          { name: "Size", width: "18.5%" },
          { name: "Supplier", width: "15.6%" },
          { name: "Completed", width: "8.9%" },
        ]}
        rows={itemRows}
        nextLink={currentPage < totalPages ? nextPageLink : undefined}
        prevLink={currentPage > 1 ? prevPageLink : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
        onRowClick={handleRowClick}
        draggableRows={sort === "rank-dsc"}
        onDrop={sort === "rank-dsc" ? onDrop : undefined}
        onRightClick={(id) => {
          const url = window.location.href.split("?")[0];
          return `${url}/${id}`;
        }}
      />
    </>
  );
}
