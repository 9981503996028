import React from "react";
import BreadCrums from "../../../components/breadcrums";
import CustomLink from "../../../components/custom-link";
import { ReactComponent as IconAdd } from "../../../assets/icons/Add.svg";
import styles from "./shape-details.module.css";
import { Form } from "react-router-dom";
import Control from "../../../components/control";
import PrimaryButton from "../../../components/primary-button";
import Dropdown from "../../../components/dropdown";
import Drawing from "./drawing";
import { ReactFlowProvider } from "reactflow";

const categoryOptions = [
  {
    label: "Custom Orb",
    value: "CustomOrb",
  },
  {
    label: "Klip Lok",
    value: "KlipLok",
  },
  {
    label: "Trimdek / Spandek",
    value: "TrimdekSpandek",
  },
];

export default function ShapeDetails({
  heading = "Create Shape",
  onFormChange,
  image,
  name,
  category,
  diagram,
  showSubmit,
  submitting,
}) {
  const [draw, setDraw] = React.useState(false);
  const [img, setImg] = React.useState(image);
  const [shape, setShape] = React.useState(diagram);
  const formRef = React.useRef(null);

  function onCompleted(img, data) {
    setImg(img);
    setShape(data);
  }

  function validate() {
    const buttonElement = document.getElementById("shape-button");
    const diagram = buttonElement.getAttribute("data-diagram");
    const imgElement = document.getElementById("shape-image");
    const dataUrl = imgElement.getAttribute("src");
    const nameInput = document.getElementById("flashing-shape-name");
    if (!dataUrl || !diagram) {
      nameInput.setCustomValidity("Please enter a name and draw a shape");
      formRef.current.reportValidity();
      return;
    } else {
      nameInput.setCustomValidity("");
      formRef.current.requestSubmit();
    }
  }

  return (
    <div className={styles.container}>
      <BreadCrums
        segments={[
          { label: "Flashings Shape Library", link: ".." },
          { label: heading, link: "." },
        ]}
      />
      <div className={styles.headingContainer}>
        <div className={styles.heading}>{heading}</div>
        <CustomLink
          text="Create Item"
          icon={<IconAdd />}
          href="/user/flashings/add"
        />
      </div>
      <Form
        onChange={onFormChange}
        method="post"
        className={styles.form}
        ref={formRef}
      >
        <div className={styles.imageContainer}>
          <img
            src={img}
            alt="Shape Preview"
            className={styles.image}
            id="shape-image"
          />
        </div>
        <div>
          <Control
            label="Shape Name"
            id="flashing-shape-name"
            name="Flashing_Shape_Name"
            required
            initialValue={name}
          />
          <div className={styles.spacer} />
          <Dropdown
            label="Shape Category"
            id="flashing-shape-category"
            name="Flashing_Shape_Category"
            required
            value={category}
            options={categoryOptions}
            placeholder="Select Category"
          />
          <div className={styles.buttonContainer}>
            <PrimaryButton
              label="Draw Shape"
              onClick={() => {
                setDraw(true);
                if (onFormChange) {
                  onFormChange();
                }
              }}
              variant="outlined"
              id="shape-button"
              data-diagram={shape}
            />
          </div>
        </div>
        {(showSubmit === undefined || showSubmit) && (
          <div className={styles.submitContainer}>
            <PrimaryButton
              label={showSubmit ? "Save Changes" : heading}
              type="button"
              loading={submitting}
              onClick={validate}
            />
          </div>
        )}
        <ReactFlowProvider>
          <Drawing
            open={draw}
            onClose={() => setDraw(false)}
            onCompleted={onCompleted}
            shape={shape}
          />
        </ReactFlowProvider>
      </Form>
    </div>
  );
}
